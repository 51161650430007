import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import { http } from './providers/http'
import { validate } from 'vee-validate'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/panel',
      name:"panel",
      component: () => import('@/views/dashboard/Index'),
      children: [
        // // Dashboard
        // {
        //   name: 'Dashboard',
        //   path: '',
        //   component: () => import('@/views/dashboard/Dashboard'),
        // },
        //Pages
        {
          name: 'Comprobantes',
          path: 'pages/comprobantes',
          component: () => import('@/views/dashboard/pages/PageComprobantes'),
        },
        // {
        //   name: 'Préstamos',
        //   path: 'pages/prestamos',
        //   component: () => import('@/views/dashboard/pages/PageEstadosCuenta'),
        // },
        // {
        //   name: 'Expediente',
        //   path: 'pages/expediente',
        //   component: () => import('@/views/dashboard/pages/PageExpediente'),
        // },
        {
          name: 'Perfil de usuario',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },
      ],

    },
    {

      path:'/historial-comprobantes',
      component: () => import('@/views/dashboard/Index'),
      children:[
        {
          name:'historial-comprobantes',
          path:'/',
          component:() => import('@/views/dashboard/pages/PageCargarComprobantes'),
        }
      ]
    },
    {
      name: 'Login',
      path: '/',
      component: () => import('@/views/dashboard/pages/Login'),
    }
  ],
})
