import axios from 'axios';
import { config } from '../config';
import store from '../store';
export const http = axios.create({
    baseURL: config.baseURL,
});

export async function validation(){

    try{

        let token = localStorage.getItem('token');
        if(token != null){
            let form = new FormData();
            form.append("token",token.split(" ")[1]);
            let response = await http.post("validate-token",form);
            if(response.data.valid){
                localStorage.setItem('token',token);
                http.defaults.headers.common["Authorization"] = token;
                store.commit("setToken",token.split(" ")[1]);
                store.commit("setUser",response.data.user);
                store.commit("setAutenticated",true);
                store.commit("setActiveHistorialComprobantes",response.data.active_historial_comprobantes);
                return true;
            }else {
                localStorage.removeItem('token');
                return false;
            }
        }
        return false;

    }catch(error){
        localStorage.removeItem('token');
        return false;
    }
    
}

