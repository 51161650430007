import Vue from 'vue'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import {
  email,
  max,
  min,
  numeric,
  required,
  confirmed,
  ext,
  is_not
} from 'vee-validate/dist/rules'

extend('max', max)
extend('numeric', numeric)

extend('required',{
  ...required,
  message: "El campo es obligatorio",
});
extend("min",{
  ...min,
  message: "EL CAMPO DEBE DE TENER COMO MINIMO {length} CARACTERES"
});

extend("confirmed",{
  ...confirmed,
  message: "La confirmación de la contraseña no coincide"
})

extend("is_not",{
  ...is_not,
  message: "No debe ser igual a la CURP"
})

extend('validarpassword',{
  message: "Debe tener por lo menos una mayúscula, caracter especial y un número",
  validate:(value) => {
    let expresion = /(.*[\W]+.*[0-9]+.*[A-Z]+.*)|(.*[\W]+.*[A-Z]+.*[0-9]+.*)|(.*[A-Z]+.*[\W]+.*[0-9]+.*)|(.*[A-Z]+.*[0-9]+.*[\W]+.*)|(.*[0-9]+.*[\W]+.*[A-Z]+.*)|(.*[0-9]+.*[A-Z]+.*[\W]+.*)/g;
    return expresion.test(value);
  }
})
extend('ext',{
  ...ext,
  message: "La extensión del archivo no es valida",
})

extend('email',{
  ...email,
  message: "El correo electrónico no es válido",
})

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
