// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate';
import './plugins/vue-tables';
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { http, validation } from './providers/http';
import NProgress from './plugins/nprogress';
import Swal from 'sweetalert';

Vue.config.productionTip = false
Vue.prototype.$http = http;
Vue.prototype.$NProgress = NProgress;
Vue.prototype.$swal = Swal;

router.beforeEach(async (to,from,next) => {
  NProgress.start();
  let validate = await validation();


  if(to.name != "Login" && !validate){
    next({name:'Login'});
  } else if(to.name == "Login" && validate){
    if(store.state.user.initial_password == "1"){
      next({name: "Perfil de usuario"});
    }else{
      next({name: "Comprobantes"});
    }
  }else if(to.name == "Login" && !validate){
    next()
  } else if(!to.name && validate){
    if(store.state.user.initial_password == "1"){
      next({name: "Perfil de usuario"});
    }else{
      next({name: "Comprobantes"});
    }
  }else if(store.state.user !== null && to.name != "Perfil de usuario"){
    if(from.name !== "Perfil de usuario" && store.state.user.initial_password == "1" ){
      next({name: "Perfil de usuario"});
    }else if(from.name !== "Perfil de usuario" && store.state.user.initial_password == "0" ){
      next()
    }else if(store.state.user.initial_password == "0" ){
      next()
    }else{
      NProgress.done();
    }
  }else {
    next();
  }
});


router.afterEach((to,from) => {
  NProgress.done();
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
